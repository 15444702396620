import React, { useMemo } from 'react';
import { bool } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_STRIPE_FEE, propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import config from '../../config';

import css from './PaymentBreakdown.module.css';

const { Money } = sdkTypes;

// Stripe fee details
const STRIPE_FEE_PERCENTAGE = 2.9;
const STRIPE_FIXED_FEE = 30;

/**
 * Formats money with intl and currency.
 */
const formatFee = (intl, amount) => {
  return formatMoney(intl, new Money(amount, config.currency));
};

const LineItemTaxMaybe = props => {
  const { lineItems, isProvider, intl } = props;

  const stripeFeeLineItem = lineItems.find(
    item => item.code === LINE_ITEM_STRIPE_FEE && !item.reversal
  );

  if (!stripeFeeLineItem || !isProvider) {
    return null;
  }

  const formattedFixedFee = formatFee(intl, STRIPE_FIXED_FEE);
  const formattedLineTotal = stripeFeeLineItem?.lineTotal
    ? formatMoney(intl, stripeFeeLineItem.lineTotal)
    : null;

  return stripeFeeLineItem?.lineTotal ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage
          id="PaymentBreakdown.stripeFee"
          values={{
            percentage: STRIPE_FEE_PERCENTAGE,
            fixed: formattedFixedFee,
          }}
        />
      </span>
      <span className={css.itemValue}>{formattedLineTotal}</span>
    </div>
  ) : null;
};

LineItemTaxMaybe.defaultProps = {
  isProvider: false,
};

LineItemTaxMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  isProvider: bool.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemTaxMaybe;
