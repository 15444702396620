import React from 'react';
import { bool } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { txIsCanceled, txIsDelivered, txIsDeclined } from '../../util/transaction';
import { LINE_ITEM_TAX, propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';

import css from './PaymentBreakdown.module.css';

const { Money } = sdkTypes;

const LineItemTotalPrice = props => {
  const { transaction, isProvider, taxData, intl } = props;

  const lineItems = transaction?.attributes?.lineItems;

  // Select appropriate message ID for providers
  const providerTotalMessages = {
    delivered: 'PaymentBreakdown.providerTotalDelivered',
    declined: 'PaymentBreakdown.providerTotalDeclined',
    canceled: 'PaymentBreakdown.providerTotalCanceled',
    default: 'PaymentBreakdown.providerTotalDefault',
  };

  const providerTotalMessageId = txIsDelivered(transaction)
    ? providerTotalMessages.delivered
    : txIsDeclined(transaction)
    ? providerTotalMessages.declined
    : txIsCanceled(transaction)
    ? providerTotalMessages.canceled
    : providerTotalMessages.default;

  const totalLabel = isProvider ? (
    <FormattedMessage id={providerTotalMessageId} />
  ) : (
    <FormattedMessage id="PaymentBreakdown.total" />
  );

  // Determine base total (without tax)
  const baseTotal = isProvider
    ? transaction.attributes.payoutTotal
    : transaction.attributes.payinTotal;

  // If taxPrice exists, add it to baseTotal
  const taxPrice = taxData?.taxPrice;
  const addTaxPrice =
    !isProvider && !lineItems.find(item => item.code === LINE_ITEM_TAX) && taxPrice;
  const totalPrice = addTaxPrice
    ? new Money(baseTotal.amount + taxPrice.amount, baseTotal.currency)
    : baseTotal;

  const formattedTotalPrice = formatMoney(intl, totalPrice);

  return (
    <div className={css.lineItem}>
      <div className={css.itemLabel}>{totalLabel}</div>
      <div className={css.itemValue}>{formattedTotalPrice}</div>
    </div>
  );
};

LineItemTotalPrice.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isProvider: bool.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemTotalPrice;
