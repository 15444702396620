import React from 'react';
import { object } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_UNITS, propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import css from './PaymentBreakdown.module.css';

const { Money } = sdkTypes;

const LineItemTaxMaybe = props => {
  const { lineItems, isCustomer, intl, taxData } = props;

  const unitPurchase = lineItems.find(item => item.code === LINE_ITEM_UNITS && !item.reversal);
  const taxLineItem = lineItems.find(item => item.code === 'line-item/tax' && !item.reversal);

  if (!taxLineItem || !isCustomer || !taxData || !taxData.taxBreakdown.length) {
    return null;
  }

  return (
    <div className={css.taxLineItem}>
      <div className={css.lineItem}>
        <span className={css.itemLabel}>
          <FormattedMessage id="PaymentBreakdown.tax" />
        </span>
      </div>
      <div className={css.taxBreakdown}>
        {taxData.taxBreakdown.map((tax, index) => (
          <div key={index} className={css.lineItem}>
            <span className={css.itemLabel}>
              <FormattedMessage
                id="PaymentBreakdown.taxBreakdown"
                values={{
                  taxType: tax.tax_type.toUpperCase(),
                  percentage: ((tax.amount / unitPurchase.lineTotal.amount) * 100).toFixed(2),
                }}
              />
            </span>
            <span className={css.itemValue}>
              {formatMoney(intl, new Money(tax.amount, taxData.taxPrice.currency))}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

LineItemTaxMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  intl: intlShape.isRequired,
  taxData: object,
};

export default LineItemTaxMaybe;
