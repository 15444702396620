import React from 'react';
import { object } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import moment from 'moment-timezone';

import css from './BookingBreakdown.module.css';

const defaultTimeZone = () => (typeof window !== 'undefined' ? moment.tz.guess() : 'Etc/UTC');

const BookingPeriod = props => {
  const { timeslot, bookingDates } = props;
  const { bookingStart, bookingEnd } = bookingDates;

  const timeZone = defaultTimeZone();

  const formattedBookingStartTime = moment(bookingStart)
    .tz(timeZone)
    .format('ddd, hh:mm A');
  const formattedBookingEndTime = moment(bookingEnd)
    .tz(timeZone)
    .format('ddd, hh:mm A');

  const formattedBookingStartDate = moment(bookingStart)
    .tz(timeZone)
    .format('MMM DD');
  const formattedBookingEndDate = moment(bookingEnd)
    .tz(timeZone)
    .format('MMM DD');

  return (
    <div className={css.lineItems}>
      <div className={css.lineItem}>
        <span className={css.itemLabel}>{formattedBookingStartTime}</span>
        <span className={css.itemValue}>{formattedBookingEndTime}</span>
      </div>
      <div className={css.lineItem}>
        <span className={css.itemLabel}>{formattedBookingStartDate}</span>
        <span className={css.itemValue}>{formattedBookingEndDate}</span>
      </div>
      {timeslot?.quantity ? (
        <div className={css.lineItem}>
          <span className={css.itemLabel}>
            <FormattedMessage id="BookingBreakdown.guests" />
          </span>
          <span className={css.itemValue}>{timeslot.quantity}</span>
        </div>
      ) : null}
    </div>
  );
};

const LineItemBookingDetails = props => {
  const { timeslot, bookingDates } = props;

  if (!bookingDates?.bookingStart && !bookingDates?.bookingEnd) {
    return null;
  }

  return (
    <div className={css.details}>
      <h4 className={css.detailsTitle}>
        <FormattedMessage id="BookingBreakdown.bookingDetails" />
      </h4>
      <BookingPeriod timeslot={timeslot} bookingDates={bookingDates} />
    </div>
  );
};

LineItemBookingDetails.propTypes = {
  bookingDates: object,
};

export default LineItemBookingDetails;
