import omit from 'lodash/omit';
import { types as sdkTypes } from '../../util/sdkLoader';
import { resetToStartOfDay, getDefaultTimeZoneOnBrowser } from '../../util/dates';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { integrationAPI } from '../../util/api';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import {
  createStripeAccount,
  updateStripeAccount,
  fetchStripeAccount,
} from '../../ducks/stripeConnectAccount.duck';
import { googleStorageAPI } from '../../util/api';
import { fetchOrganizationProfiles } from '../../ducks/organizations.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { showListingInvites } from '../../ducks/invites.duck';
import config from '../../config';
import * as log from '../../util/log';

const { UUID } = sdkTypes;

// Helper to get the correct public data property based on imageType
const getPromotePublicDataProperty = imageType => {
  return imageType === 'poster' ? 'posterImageId' : 'coverImageId';
};

// Helper to select the correct SDK based on ownership
const getListingSdk = (isOwnListing, sdk) => {
  return isOwnListing ? sdk.ownListings : integrationAPI.listings;
};

// Return an array of image ids
const imageIds = images => {
  // For newly uploaded image the UUID can be found from "img.imageId"
  // and for existing listing images the id is "img.id"
  return images ? images.map(img => img.imageId || img.id) : null;
};

// After listing creation & update, we want to make sure that uploadedImages state is cleaned
const updateUploadedImagesState = (state, payload) => {
  const { uploadedImages, uploadedImagesOrder } = state;

  // Images attached to listing entity
  const attachedImages = payload?.data?.relationships?.images?.data || [];
  const attachedImageUUIDStrings = attachedImages.map(img => img.id.uuid);

  // Uploaded images (which are propably not yet attached to listing)
  const unattachedImages = Object.values(state.uploadedImages);
  const duplicateImageEntities = unattachedImages.filter(unattachedImg =>
    attachedImageUUIDStrings.includes(unattachedImg.imageId?.uuid)
  );

  return duplicateImageEntities.length > 0
    ? {
        uploadedImages: {},
        uploadedImagesOrder: [],
      }
    : {
        uploadedImages,
        uploadedImagesOrder,
      };
};

const requestAction = actionType => params => ({ type: actionType, payload: { params } });
const successAction = actionType => result => ({ type: actionType, payload: result.data });
const errorAction = actionType => error => ({ type: actionType, payload: error, error: true });

// ================ Action types ================ //

export const MARK_TAB_UPDATED = 'app/EditExperiencePage/MARK_TAB_UPDATED';
export const CLEAR_UPDATED_TAB = 'app/EditExperiencePage/CLEAR_UPDATED_TAB';
export const CLEAR_UPDATED_CONTENT = 'app/EditExperiencePage/CLEAR_UPDATED_CONTENT';

export const CREATE_LISTING_DRAFT_REQUEST = 'app/EditExperiencePage/CREATE_LISTING_DRAFT_REQUEST';
export const CREATE_LISTING_DRAFT_SUCCESS = 'app/EditExperiencePage/CREATE_LISTING_DRAFT_SUCCESS';
export const CREATE_LISTING_DRAFT_ERROR = 'app/EditExperiencePage/CREATE_LISTING_DRAFT_ERROR';

export const PUBLISH_LISTING_REQUEST = 'app/EditExperiencePage/PUBLISH_LISTING_REQUEST';
export const PUBLISH_LISTING_SUCCESS = 'app/EditExperiencePage/PUBLISH_LISTING_SUCCESS';
export const PUBLISH_LISTING_ERROR = 'app/EditExperiencePage/PUBLISH_LISTING_ERROR';

export const UPDATE_LISTING_REQUEST = 'app/EditExperiencePage/UPDATE_LISTING_REQUEST';
export const UPDATE_LISTING_SUCCESS = 'app/EditExperiencePage/UPDATE_LISTING_SUCCESS';
export const UPDATE_LISTING_ERROR = 'app/EditExperiencePage/UPDATE_LISTING_ERROR';

export const SHOW_LISTINGS_REQUEST = 'app/EditExperiencePage/SHOW_LISTINGS_REQUEST';
export const SHOW_LISTINGS_SUCCESS = 'app/EditExperiencePage/SHOW_LISTINGS_SUCCESS';
export const SHOW_LISTINGS_ERROR = 'app/EditExperiencePage/SHOW_LISTINGS_ERROR';

export const UPLOAD_IMAGE_REQUEST = 'app/EditExperiencePage/UPLOAD_IMAGE_REQUEST';
export const UPLOAD_IMAGE_SUCCESS = 'app/EditExperiencePage/UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_ERROR = 'app/EditExperiencePage/UPLOAD_IMAGE_ERROR';

export const REMOVE_LISTING_IMAGE = 'app/EditExperiencePage/REMOVE_LISTING_IMAGE';

export const UPLOAD_VIDEO_REQUEST = 'app/EditExperiencePage/UPLOAD_VIDEO_REQUEST';
export const UPLOAD_VIDEO_SUCCESS = 'app/EditExperiencePage/UPLOAD_VIDEO_SUCCESS';
export const UPLOAD_VIDEO_ERROR = 'app/EditExperiencePage/UPLOAD_VIDEO_ERROR';

export const REMOVE_LISTING_VIDEO = 'app/EditExperiencePage/REMOVE_LISTING_VIDEO';

export const FETCH_EXCEPTIONS_REQUEST =
  'app/EditExperiencePage/FETCH_AVAILABILITY_EXCEPTIONS_REQUEST';
export const FETCH_EXCEPTIONS_SUCCESS =
  'app/EditExperiencePage/FETCH_AVAILABILITY_EXCEPTIONS_SUCCESS';
export const FETCH_EXCEPTIONS_ERROR = 'app/EditExperiencePage/FETCH_AVAILABILITY_EXCEPTIONS_ERROR';

export const ADD_EXCEPTION_REQUEST = 'app/EditExperiencePage/ADD_AVAILABILITY_EXCEPTION_REQUEST';
export const ADD_EXCEPTION_SUCCESS = 'app/EditExperiencePage/ADD_AVAILABILITY_EXCEPTION_SUCCESS';
export const ADD_EXCEPTION_ERROR = 'app/EditExperiencePage/ADD_AVAILABILITY_EXCEPTION_ERROR';

export const DELETE_EXCEPTION_REQUEST =
  'app/EditExperiencePage/DELETE_AVAILABILITY_EXCEPTION_REQUEST';
export const DELETE_EXCEPTION_SUCCESS =
  'app/EditExperiencePage/DELETE_AVAILABILITY_EXCEPTION_SUCCESS';
export const DELETE_EXCEPTION_ERROR = 'app/EditExperiencePage/DELETE_AVAILABILITY_EXCEPTION_ERROR';

export const ADD_REPEATING_EXCEPTIONS_REQUEST =
  'app/EditExperiencePage/ADD_AVAILABILITY_EXCEPTIONS_REQUEST';
export const ADD_REPEATING_EXCEPTIONS_SUCCESS =
  'app/EditExperiencePage/ADD_AVAILABILITY_EXCEPTIONS_SUCCESS';
export const ADD_REPEATING_EXCEPTIONS_ERROR =
  'app/EditExperiencePage/ADD_AVAILABILITY_EXCEPTIONS_ERROR';

export const DELETE_REPEATING_EXCEPTIONS_REQUEST =
  'app/EditExperiencePage/DELETE_AVAILABILITY_EXCEPTIONS_REQUEST';
export const DELETE_REPEATING_EXCEPTIONS_SUCCESS =
  'app/EditExperiencePage/DELETE_AVAILABILITY_EXCEPTIONS_SUCCESS';
export const DELETE_REPEATING_EXCEPTIONS_ERROR =
  'app/EditExperiencePage/DELETE_AVAILABILITY_EXCEPTIONS_ERROR';

export const SAVE_PAYOUT_DETAILS_REQUEST = 'app/EditExperiencePage/SAVE_PAYOUT_DETAILS_REQUEST';
export const SAVE_PAYOUT_DETAILS_SUCCESS = 'app/EditExperiencePage/SAVE_PAYOUT_DETAILS_SUCCESS';
export const SAVE_PAYOUT_DETAILS_ERROR = 'app/EditExperiencePage/SAVE_PAYOUT_DETAILS_ERROR';

export const PROMOTE_IMAGE_REQUEST = 'app/EditExperiencePage/PROMOTE_IMAGE_REQUEST';
export const PROMOTE_IMAGE_SUCCESS = 'app/EditExperiencePage/PROMOTE_IMAGE_SUCCESS';
export const PROMOTE_IMAGE_ERROR = 'app/EditExperiencePage/PROMOTE_IMAGE_ERROR';

export const QUERY_SHOWS_REQUEST = 'app/EditExperiencePage/QUERY_SHOWS_REQUEST';
export const QUERY_SHOWS_SUCCESS = 'app/EditExperiencePage/QUERY_SHOWS_SUCCESS';
export const QUERY_SHOWS_ERROR = 'app/EditExperiencePage/QUERY_SHOWS_ERROR';

/**
 * Helper function that denormalises the availabilityExceptions from
 * integration API response (e.g we need to format date).
 *
 * @param {array} availabilityExceptions
 *
 * @return {array} denormalised availabilityExceptions array
 */
const denormaliseIntegrationAvailabilityExceptions = availabilityExceptions =>
  availabilityExceptions.map(e => ({
    ...e,
    attributes: {
      ...e.attributes,
      start: new Date(e.attributes.start),
      end: new Date(e.attributes.end),
    },
  }));

// ================ Reducer ================ //

const initialState = {
  // Error instance placeholders for each endpoint
  createListingDraftError: null,
  publishingListing: null,
  publishListingError: null,
  updateListingError: null,
  showListingsError: null,
  uploadImageError: null,
  uploadVideoError: null,
  createListingDraftInProgress: false,
  submittedListingId: null,
  redirectToListing: false,
  uploadedImages: {},
  uploadedImagesOrder: [],
  removedImageIds: [],
  uploadedVideos: {},
  uploadedVideosOrder: [],
  removedVideoIds: [],
  fetchExceptionsError: null,
  fetchExceptionsInProgress: false,
  availabilityExceptions: [],
  addExceptionError: null,
  addExceptionInProgress: false,
  deleteExceptionId: null,
  deleteExceptionError: null,
  deleteExceptionInProgress: false,
  addRepeatingExceptionsInProgress: false,
  addRepeatingExceptionsError: null,
  deleteRepeatingExceptionsInProgress: false,
  deleteRepeatingExceptionsError: null,
  listingDraft: null,
  updatedTab: null,
  updateInProgress: false,
  payoutDetailsSaveInProgress: false,
  payoutDetailsSaved: false,
  showListings: [],
  queryShowsInProgress: false,
  queryShowsError: null,
  promoteImageInProgress: false,
  promoteImageError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case MARK_TAB_UPDATED:
      return { ...state, updatedTab: payload };
    case CLEAR_UPDATED_TAB:
      return { ...state, updatedTab: null, updateListingError: null };
    case CLEAR_UPDATED_CONTENT:
      return {
        ...state,
        uploadedImages: {},
        uploadedImagesOrder: [],
        removedImageIds: [],
        uploadedVideos: {},
        uploadedVideosOrder: [],
        removedVideoIds: [],
      };

    case CREATE_LISTING_DRAFT_REQUEST:
      return {
        ...state,
        createListingDraftInProgress: true,
        createListingDraftError: null,
        submittedListingId: null,
        listingDraft: null,
      };
    case CREATE_LISTING_DRAFT_SUCCESS:
      return {
        ...state,
        ...updateUploadedImagesState(state, payload),
        createListingDraftInProgress: false,
        submittedListingId: payload.data.id,
        listingDraft: payload.data,
      };
    case CREATE_LISTING_DRAFT_ERROR:
      return {
        ...state,
        createListingDraftInProgress: false,
        createListingDraftError: payload,
      };

    case PUBLISH_LISTING_REQUEST:
      return {
        ...state,
        publishingListing: payload.listingId,
        publishListingError: null,
      };
    case PUBLISH_LISTING_SUCCESS:
      return {
        ...state,
        redirectToListing: true,
        publishingListing: null,
        createListingDraftError: null,
        updateListingError: null,
        showListingsError: null,
        uploadImageError: null,
        createListingDraftInProgress: false,
        updateInProgress: false,
      };
    case PUBLISH_LISTING_ERROR: {
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        publishingListing: null,
        publishListingError: {
          listingId: state.publishingListing,
          error: payload,
        },
      };
    }

    case UPDATE_LISTING_REQUEST:
      return { ...state, updateInProgress: true, updateListingError: null };
    case UPDATE_LISTING_SUCCESS:
      return { ...state, ...updateUploadedImagesState(state, payload), updateInProgress: false };
    case UPDATE_LISTING_ERROR:
      return { ...state, updateInProgress: false, updateListingError: payload };

    case SHOW_LISTINGS_REQUEST:
      return { ...state, showListingsError: null };
    case SHOW_LISTINGS_SUCCESS:
      return { ...state, images: {}, imageOrder: [], removedImageIds: [] };
    case SHOW_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, showListingsError: payload, redirectToListing: false };

    case UPLOAD_IMAGE_REQUEST: {
      // payload.params: { id: 'tempId', file }
      const uploadedImages = {
        ...state.uploadedImages,
        [payload.params.id]: { ...payload.params },
      };
      return {
        ...state,
        uploadedImages,
        uploadedImagesOrder: state.uploadedImagesOrder.concat([payload.params.id]),
        uploadImageError: null,
      };
    }
    case UPLOAD_IMAGE_SUCCESS: {
      // payload.params: { id: 'tempId', imageId: 'some-real-id', attributes, type }
      const { id, ...rest } = payload;
      const uploadedImages = { ...state.uploadedImages, [id]: { id, ...rest } };
      return { ...state, uploadedImages };
    }
    case UPLOAD_IMAGE_ERROR: {
      // eslint-disable-next-line no-console
      const { id, error } = payload;
      const uploadedImagesOrder = state.uploadedImagesOrder.filter(i => i !== id);
      const uploadedImages = omit(state.uploadedImages, id);
      return { ...state, uploadedImagesOrder, uploadedImages, uploadImageError: error };
    }

    case REMOVE_LISTING_IMAGE: {
      const id = payload.imageId;

      // Only mark the image removed if it hasn't been added to the
      // listing already
      const removedImageIds = state.uploadedImages[id]
        ? state.removedImageIds
        : state.removedImageIds.concat(id);

      // Always remove from the draft since it might be a new image to
      // an existing listing.
      const uploadedImages = omit(state.uploadedImages, id);
      const uploadedImagesOrder = state.uploadedImagesOrder.filter(i => i !== id);

      return { ...state, uploadedImages, uploadedImagesOrder, removedImageIds };
    }

    case UPLOAD_VIDEO_REQUEST: {
      // payload.params: { id: 'tempId', file }
      const uploadedVideos = {
        ...state.uploadedVideos,
        [payload.params.id]: { ...payload.params },
      };
      return {
        ...state,
        uploadedVideos,
        uploadedVideosOrder: state.uploadedVideosOrder.concat([payload.params.id]),
        uploadVideoError: null,
      };
    }
    case UPLOAD_VIDEO_SUCCESS: {
      // payload.params: { id: 'tempId', videoId: 'some-real-id', attributes, type }
      const { id, ...rest } = payload;
      const uploadedVideos = { ...state.uploadedVideos, [id]: { id, ...rest } };
      return { ...state, uploadedVideos };
    }
    case UPLOAD_VIDEO_ERROR: {
      // eslint-disable-next-line no-console
      const { id, error } = payload;
      const uploadedVideosOrder = state.uploadedVideosOrder.filter(i => i !== id);
      const uploadedVideos = omit(state.uploadedVideos, id);
      return { ...state, uploadedVideosOrder, uploadedVideos, uploadVideoError: error };
    }

    case REMOVE_LISTING_VIDEO: {
      const id = payload.videoId;

      // Only mark the video removed if it hasn't been added to the
      // listing already
      const removedVideoIds = state.uploadedVideos[id]
        ? state.removedVideoIds
        : state.removedVideoIds.concat(id);

      // Always remove from the draft since it might be a new video to
      // an existing listing.
      const uploadedVideos = omit(state.uploadedVideos, id);
      const uploadedVideosOrder = state.uploadedVideosOrder.filter(i => i !== id);

      return { ...state, uploadedVideos, uploadedVideosOrder, removedVideoIds };
    }

    case FETCH_EXCEPTIONS_REQUEST:
      return {
        ...state,
        availabilityExceptions: [],
        fetchExceptionsError: null,
        fetchExceptionsInProgress: true,
      };
    case FETCH_EXCEPTIONS_SUCCESS:
      return {
        ...state,
        availabilityExceptions: payload,
        fetchExceptionsError: null,
        fetchExceptionsInProgress: false,
      };
    case FETCH_EXCEPTIONS_ERROR:
      return {
        ...state,
        fetchExceptionsError: payload.error,
        fetchExceptionsInProgress: false,
      };

    case ADD_EXCEPTION_REQUEST:
      return {
        ...state,
        addExceptionError: null,
        addExceptionInProgress: true,
      };
    case ADD_EXCEPTION_SUCCESS:
      return {
        ...state,
        availabilityExceptions: [...state.availabilityExceptions, payload],
        addExceptionInProgress: false,
      };
    case ADD_EXCEPTION_ERROR:
      return {
        ...state,
        addExceptionError: payload.error,
        addExceptionInProgress: false,
      };

    case DELETE_EXCEPTION_REQUEST:
      return {
        ...state,
        deleteExceptionId: payload.params.id,
        deleteExceptionError: null,
        deleteExceptionInProgress: true,
      };
    case DELETE_EXCEPTION_SUCCESS: {
      const deletedExceptionId = payload.id;
      const availabilityExceptions = state.availabilityExceptions.filter(
        e => e.id.uuid !== deletedExceptionId.uuid
      );
      return {
        ...state,
        deleteExceptionId: null,
        availabilityExceptions,
        deleteExceptionInProgress: false,
      };
    }
    case DELETE_EXCEPTION_ERROR:
      return {
        ...state,
        deleteExceptionId: null,
        deleteExceptionError: payload.error,
        deleteExceptionInProgress: false,
      };

    case ADD_REPEATING_EXCEPTIONS_REQUEST:
      return {
        ...state,
        addRepeatingExceptionsError: null,
        addRepeatingExceptionsInProgress: true,
      };
    case ADD_REPEATING_EXCEPTIONS_SUCCESS:
      return {
        ...state,
        availabilityExceptions: [...state.availabilityExceptions, ...payload],
        addRepeatingExceptionsInProgress: false,
      };
    case ADD_REPEATING_EXCEPTIONS_ERROR:
      return {
        ...state,
        addRepeatingExceptionsError: payload.error,
        addRepeatingExceptionsInProgress: false,
      };

    case DELETE_REPEATING_EXCEPTIONS_REQUEST:
      return {
        ...state,
        deleteRepeatingExceptionsError: null,
        deleteRepeatingExceptionsInProgress: true,
      };
    case DELETE_REPEATING_EXCEPTIONS_SUCCESS: {
      const deletedAvailabilityExceptionIds = payload.map(e => e.id.uuid);
      const filterAvailabilityExceptions = state.availabilityExceptions.filter(
        e => !deletedAvailabilityExceptionIds.includes(e.id.uuid)
      );
      return {
        ...state,
        availabilityExceptions: filterAvailabilityExceptions,
        deleteRepeatingExceptionsInProgress: false,
      };
    }
    case DELETE_REPEATING_EXCEPTIONS_ERROR:
      return {
        ...state,
        deleteRepeatingExceptionsError: payload.error,
        deleteRepeatingExceptionsInProgress: false,
      };

    case SAVE_PAYOUT_DETAILS_REQUEST:
      return { ...state, payoutDetailsSaveInProgress: true };
    case SAVE_PAYOUT_DETAILS_ERROR:
      return { ...state, payoutDetailsSaveInProgress: false };
    case SAVE_PAYOUT_DETAILS_SUCCESS:
      return { ...state, payoutDetailsSaveInProgress: false, payoutDetailsSaved: true };

    case QUERY_SHOWS_REQUEST:
      return {
        ...state,
        queryShowsInProgress: true,
        queryShowsError: null,
      };
    case QUERY_SHOWS_SUCCESS:
      return {
        ...state,
        showListings: payload.showListings,
        queryShowsInProgress: false,
        queryShowsError: null,
      };
    case QUERY_SHOWS_ERROR:
      return {
        ...state,
        showListings: [],
        queryShowsInProgress: false,
        queryShowsError: payload,
      };

    case PROMOTE_IMAGE_REQUEST:
      return { ...state, promoteImageInProgress: true, promoteImageError: null };
    case PROMOTE_IMAGE_SUCCESS:
      return {
        ...state,
        promoteImageInProgress: false,
        promoteImageError: null,
      };
    case PROMOTE_IMAGE_ERROR:
      return { ...state, promoteImageInProgress: false, promoteImageError: payload };

    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const markTabUpdated = tab => ({
  type: MARK_TAB_UPDATED,
  payload: tab,
});

export const clearUpdatedTab = () => ({
  type: CLEAR_UPDATED_TAB,
});

export const clearUpdatedContent = () => ({
  type: CLEAR_UPDATED_CONTENT,
});

export const removeListingImage = imageId => ({
  type: REMOVE_LISTING_IMAGE,
  payload: { imageId },
});

export const removeListingVideo = videoId => ({
  type: REMOVE_LISTING_VIDEO,
  payload: { videoId },
});

// All the action creators that don't have the {Success, Error} suffix
// take the params object that the corresponding SDK endpoint method
// expects.

// SDK method: ownListings.create
export const createListingDraft = requestAction(CREATE_LISTING_DRAFT_REQUEST);
export const createListingDraftSuccess = successAction(CREATE_LISTING_DRAFT_SUCCESS);
export const createListingDraftError = errorAction(CREATE_LISTING_DRAFT_ERROR);

// SDK method: ownListings.publish
export const publishListing = requestAction(PUBLISH_LISTING_REQUEST);
export const publishListingSuccess = successAction(PUBLISH_LISTING_SUCCESS);
export const publishListingError = errorAction(PUBLISH_LISTING_ERROR);

// SDK method: ownListings.update
export const updateListing = requestAction(UPDATE_LISTING_REQUEST);
export const updateListingSuccess = successAction(UPDATE_LISTING_SUCCESS);
export const updateListingError = errorAction(UPDATE_LISTING_ERROR);

// SDK method: ownListings.show
export const showListings = requestAction(SHOW_LISTINGS_REQUEST);
export const showListingsSuccess = successAction(SHOW_LISTINGS_SUCCESS);
export const showListingsError = errorAction(SHOW_LISTINGS_ERROR);

// SDK method: images.upload
export const uploadImageRequest = requestAction(UPLOAD_IMAGE_REQUEST);
export const uploadImageSuccess = successAction(UPLOAD_IMAGE_SUCCESS);
export const uploadImageError = errorAction(UPLOAD_IMAGE_ERROR);

// Google Storage API: googleStorageAPI.videos.upload
export const uploadVideoRequest = requestAction(UPLOAD_VIDEO_REQUEST);
export const uploadVideoSuccess = successAction(UPLOAD_VIDEO_SUCCESS);
export const uploadVideoError = errorAction(UPLOAD_VIDEO_ERROR);

// SDK method: availabilityExceptions.query
export const fetchAvailabilityExceptionsRequest = requestAction(FETCH_EXCEPTIONS_REQUEST);
export const fetchAvailabilityExceptionsSuccess = successAction(FETCH_EXCEPTIONS_SUCCESS);
export const fetchAvailabilityExceptionsError = errorAction(FETCH_EXCEPTIONS_ERROR);

// SDK method: availabilityExceptions.create
export const addAvailabilityExceptionRequest = requestAction(ADD_EXCEPTION_REQUEST);
export const addAvailabilityExceptionSuccess = successAction(ADD_EXCEPTION_SUCCESS);
export const addAvailabilityExceptionError = errorAction(ADD_EXCEPTION_ERROR);

// SDK method: availabilityExceptions.delete
export const deleteAvailabilityExceptionRequest = requestAction(DELETE_EXCEPTION_REQUEST);
export const deleteAvailabilityExceptionSuccess = successAction(DELETE_EXCEPTION_SUCCESS);
export const deleteAvailabilityExceptionError = errorAction(DELETE_EXCEPTION_ERROR);

// flexIntegrationSdk method: availabilityExceptions.create (create multiple exceptions)
export const addRepeatingAvailabilityExceptionsRequest = requestAction(
  ADD_REPEATING_EXCEPTIONS_REQUEST
);
export const addRepeatingAvailabilityExceptionsSuccess = successAction(
  ADD_REPEATING_EXCEPTIONS_SUCCESS
);
export const addRepeatingAvailabilityExceptionsError = errorAction(ADD_REPEATING_EXCEPTIONS_ERROR);

// flexIntegrationSdk method: availabilityExceptions.delete (delete multiple exceptions)
export const deleteRepeatingAvailabilityExceptionsRequest = requestAction(
  DELETE_REPEATING_EXCEPTIONS_REQUEST
);
export const deleteRepeatingAvailabilityExceptionsSuccess = successAction(
  DELETE_REPEATING_EXCEPTIONS_SUCCESS
);
export const deleteRepeatingAvailabilityExceptionsError = errorAction(
  DELETE_REPEATING_EXCEPTIONS_ERROR
);

export const savePayoutDetailsRequest = requestAction(SAVE_PAYOUT_DETAILS_REQUEST);
export const savePayoutDetailsSuccess = successAction(SAVE_PAYOUT_DETAILS_SUCCESS);
export const savePayoutDetailsError = errorAction(SAVE_PAYOUT_DETAILS_ERROR);

export const promoteImageRequest = requestAction(PROMOTE_IMAGE_REQUEST);
export const promoteImageSuccess = successAction(PROMOTE_IMAGE_SUCCESS);
export const promoteImageError = errorAction(PROMOTE_IMAGE_ERROR);

export const queryShowsRequest = () => ({
  type: QUERY_SHOWS_REQUEST,
});
export const queryShowsSuccess = showListings => ({
  type: QUERY_SHOWS_SUCCESS,
  payload: { showListings },
});
export const queryShowsError = e => ({
  type: QUERY_SHOWS_ERROR,
  error: true,
  payload: e,
});

// ================ Thunk ================ //

export function requestShowListing(actionPayload, isOwnListing) {
  return async (dispatch, getState, sdk) => {
    dispatch(showListings(actionPayload));

    const integrationResponse = await integrationAPI.listings.show({
      ...actionPayload,
      id: actionPayload.id.uuid,
    });
    const currentListing = denormalisedResponseEntities(integrationResponse.data)[0];

    const { currentUser } = getState().user;
    const isOwn = isOwnListing || currentUser?.id?.uuid === currentListing.author.id.uuid;

    if (isOwn) {
      return sdk.ownListings
        .show(actionPayload)
        .then(response => {
          // EditExperiencePage fetches new listing data, which also needs to be added to global data
          dispatch(addMarketplaceEntities(response));
          dispatch(showListingsSuccess(response));
          return response;
        })
        .catch(e => dispatch(showListingsError(storableError(e))));
    } else {
      dispatch(addMarketplaceEntities(integrationResponse.data));
      dispatch(showListingsSuccess(integrationResponse.data));
      return integrationResponse.data;
    }
  };
}

export function requestCreateListingDraft(data) {
  return async (dispatch, getState, sdk) => {
    dispatch(createListingDraft(data));

    const queryParams = {
      expand: true,
      include: ['author', 'images'],
      'fields.image': [
        'variants.landscape-crop',
        'variants.landscape-crop2x',
        'variants.landscape-crop4x',
        'variants.landscape-crop6x',
      ],
    };

    return sdk.ownListings
      .createDraft(data, queryParams)
      .then(response => {
        //const id = response.data.data.id.uuid;

        // Add the created listing to the marketplace data
        dispatch(addMarketplaceEntities(response));

        // Modify store to understand that we have created listing and can redirect away
        dispatch(createListingDraftSuccess(response));
        return response;
      })
      .catch(e => {
        log.error(e, 'create-listing-draft-failed', { listingData: data });
        return dispatch(createListingDraftError(storableError(e)));
      });
  };
}

export const requestPublishListingDraft = listingId => (dispatch, getState, sdk) => {
  dispatch(publishListing(listingId));

  return sdk.ownListings
    .publishDraft({ id: listingId }, { expand: true })
    .then(response => {
      // Add the created listing to the marketplace data
      dispatch(addMarketplaceEntities(response));
      dispatch(publishListingSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(publishListingError(storableError(e)));
    });
};

export function requestImageUpload(actionPayload) {
  return (dispatch, getState, sdk) => {
    const id = actionPayload.id;
    const queryParams = {
      expand: true,
      'fields.image': [
        'variants.landscape-crop',
        'variants.landscape-crop2x',
        'variants.landscape-crop4x',
        'variants.landscape-crop6x',
      ],
    };

    dispatch(uploadImageRequest(actionPayload));
    return sdk.images
      .upload({ image: actionPayload.file }, queryParams)
      .then(resp => {
        const img = resp.data.data;
        // Uploaded image has an existing id that refers to file
        // The UUID was created as a consequence of this upload call - it's saved to imageId property
        return dispatch(
          uploadImageSuccess({ data: { ...img, id, imageId: img.id, file: actionPayload.file } })
        );
      })
      .catch(e => dispatch(uploadImageError({ id, error: storableError(e) })));
  };
}

export function requestVideoUpload(actionPayload) {
  return (dispatch, getState, sdk) => {
    const id = actionPayload.id;

    dispatch(uploadVideoRequest(actionPayload));

    const formData = new FormData();
    formData.append('video', actionPayload.file);

    return googleStorageAPI.videos
      .upload(formData)
      .then(resp => {
        const video = resp;
        // Uploaded video has an existing id that refers to file
        return dispatch(
          uploadVideoSuccess({
            data: { ...video, id, videoId: video.id, file: actionPayload.file },
          })
        );
      })
      .catch(e => dispatch(uploadVideoError({ id, error: storableError(e) })));
  };
}

// Update the given tab of the wizard with the given data. This saves
// the data to the listing, and marks the tab updated so the UI can
// display the state.
export function requestUpdateListing(tab, data, isOwnListing) {
  return async (dispatch, getState, sdk) => {
    dispatch(updateListing(data));

    const { images } = data;
    const imageProperty = images ? { images: imageIds(images) } : {};

    const updateValues = { ...data, ...imageProperty };

    const imageVariantParams = {
      'fields.image': [
        'variants.landscape-crop',
        'variants.landscape-crop2x',
        'variants.landscape-crop4x',
        'variants.landscape-crop6x',
      ],
    };

    const includeParams = {
      expand: true,
      include: ['author', 'images'],
      ...imageVariantParams,
    };

    const api = isOwnListing ? sdk.ownListings : integrationAPI.listings;

    try {
      const updateResponse = await api.update(updateValues, includeParams);

      dispatch(markTabUpdated(tab));
      dispatch(addMarketplaceEntities(updateResponse));
      dispatch(updateListingSuccess(updateResponse));

      return updateResponse;
    } catch (e) {
      log.error(e, 'update-listing-failed', { listingData: data });
      dispatch(updateListingError(storableError(e)));
      throw e;
    }
  };
}

export const requestAddAvailabilityException = (params, isOwnListing) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(addAvailabilityExceptionRequest(params));

  const useSdk = isOwnListing ? sdk.availabilityExceptions : integrationAPI.availabilityExceptions;

  return useSdk
    .create(params, { expand: true })
    .then(response => {
      const availabilityException = response.data.data;
      dispatch(addAvailabilityExceptionSuccess({ data: availabilityException }));

      return availabilityException;
    })
    .catch(e => {
      dispatch(addAvailabilityExceptionError({ error: storableError(e) }));
      throw e;
    });
};

export const requestDeleteAvailabilityException = (params, isOwnListing) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(deleteAvailabilityExceptionRequest(params));

  const useSdk = isOwnListing ? sdk.availabilityExceptions : integrationAPI.availabilityExceptions;

  return useSdk
    .delete(params, { expand: true })
    .then(response => {
      const availabilityException = response.data.data;
      return dispatch(deleteAvailabilityExceptionSuccess({ data: availabilityException }));
    })
    .catch(e => {
      dispatch(deleteAvailabilityExceptionError({ error: storableError(e) }));
      throw e;
    });
};

export const requestFetchAvailabilityExceptions = (fetchParams, isOwnListing) => async (
  dispatch,
  getState,
  sdk
) => {
  dispatch(fetchAvailabilityExceptionsRequest(fetchParams));

  const integrationResponse = await integrationAPI.listings.show({
    id: fetchParams.listingId.uuid,
    include: ['author'],
  });
  const currentListing = denormalisedResponseEntities(integrationResponse.data)[0];

  const { currentUser } = getState().user;
  const isOwn = isOwnListing || currentUser?.id?.uuid === currentListing.author.id.uuid;

  const useSdk = isOwn ? sdk.availabilityExceptions : integrationAPI.availabilityExceptions;
  const params = isOwn ? fetchParams : { ...fetchParams, listingId: fetchParams.listingId.uuid };

  return useSdk
    .query(params, { expand: true })
    .then(fetchResponse => {
      const response = isOwn ? fetchResponse : fetchResponse.data;
      const availabilityExceptions = denormalisedResponseEntities(response);
      return dispatch(fetchAvailabilityExceptionsSuccess({ data: availabilityExceptions }));
    })
    .catch(e => {
      return dispatch(fetchAvailabilityExceptionsError({ error: storableError(e) }));
    });
};

export const requestAddRepeatingAvailabilityExceptions = params => (dispatch, getState, sdk) => {
  dispatch(addRepeatingAvailabilityExceptionsRequest(params));

  return integrationAPI.availabilityExceptions
    .createMultiple(params)
    .then(response => {
      const availabilityExceptions = response.data.data;
      const denormalisedAvailabilityExceptions = denormaliseIntegrationAvailabilityExceptions(
        availabilityExceptions
      );

      dispatch(
        addRepeatingAvailabilityExceptionsSuccess({ data: denormalisedAvailabilityExceptions })
      );
      return availabilityExceptions;
    })
    .catch(e => {
      dispatch(addRepeatingAvailabilityExceptionsError({ error: storableError(e) }));
      throw e;
    });
};

export const requestDeleteRepeatingAvailabilityExceptions = params => (dispatch, getState, sdk) => {
  dispatch(deleteRepeatingAvailabilityExceptionsRequest(params));

  return integrationAPI.availabilityExceptions
    .deleteMultiple(params)
    .then(response => {
      const availabilityExceptions = response.data.data;
      return dispatch(
        deleteRepeatingAvailabilityExceptionsSuccess({ data: availabilityExceptions })
      );
    })
    .catch(e => {
      dispatch(deleteRepeatingAvailabilityExceptionsError({ error: storableError(e) }));
      throw e;
    });
};

export const savePayoutDetails = (values, isUpdateCall) => (dispatch, getState, sdk) => {
  const upsertThunk = isUpdateCall ? updateStripeAccount : createStripeAccount;
  dispatch(savePayoutDetailsRequest());

  return dispatch(upsertThunk(values, { expand: true }))
    .then(response => {
      dispatch(savePayoutDetailsSuccess());
      return response;
    })
    .catch(() => dispatch(savePayoutDetailsError()));
};

export const queryShows = organizationProfiles => async dispatch => {
  dispatch(queryShowsRequest());

  try {
    const showListings = await dispatch(queryShowListingsByOrganizations(organizationProfiles));
    dispatch(queryShowsSuccess(showListings));
  } catch (e) {
    dispatch(queryShowsError(storableError(e)));
  }
};

const queryShowListingsByOrganizations = organizationProfiles => async (
  dispatch,
  getState,
  sdk
) => {
  const showPromises = organizationProfiles.map(async o => {
    try {
      const response = await integrationAPI.listings.query({
        pub_organizationId: o.id,
        pub_type: config.listingTypes['show'],
      });

      dispatch(addMarketplaceEntities(response.data));

      return response.data.data.data.map(listing => ({
        id: listing.id,
        title: listing.attributes.title,
      }));
    } catch (error) {
      console.error('Error querying listings:', error);
      return [];
    }
  });

  return (await Promise.all(showPromises)).flat();
};

const queryShowListingsByCredits = showListings => async (dispatch, getState, sdk) => {
  const { currentUser } = getState().user;
  const currentUserCreditsFromPublicData = currentUser.attributes.profile.publicData.credits || [];

  const response = await integrationAPI.listings.query({
    ids: currentUserCreditsFromPublicData.map(l => l.id),
    pub_type: config.listingTypes['show'],
  });

  dispatch(addMarketplaceEntities(response.data));

  const listings = response.data.data.data
    .map(listing => ({
      id: listing.id,
      title: listing.attributes.title,
    }))
    .filter(l => !showListings.map(lis => lis.id.uuid).includes(l.id.uuid));

  return listings;
};

export const promoteImage = (listingId, imageId, imageType, isOwnListing) => async (
  dispatch,
  getState,
  sdk
) => {
  try {
    dispatch(promoteImageRequest());

    const publicDataProperty = getPromotePublicDataProperty(imageType);
    const listingSdk = getListingSdk(isOwnListing, sdk);

    const updateResponse = await listingSdk.update(
      {
        id: listingId,
        publicData: {
          [publicDataProperty]: imageId ? imageId.uuid : null,
        },
      },
      { expand: true }
    );

    dispatch(addMarketplaceEntities(updateResponse));
    dispatch(promoteImageSuccess(updateResponse));

    return updateResponse;
  } catch (error) {
    dispatch(promoteImageError(storableError(error)));
  }
};

// loadData is run for each tab of the wizard. When editing an
// existing listing, the listing must be fetched first.
export const loadData = params => async (dispatch, getState, sdk) => {
  dispatch(clearUpdatedTab());
  dispatch(clearUpdatedContent());

  return dispatch(fetchOrganizationProfiles()).then(async organizationProfiles => {
    const { id, type } = params;

    if (type === 'new') {
      // No need to listing data when creating a new listing
      try {
        const response = await Promise.all([dispatch(fetchCurrentUser())]);
        const currentUser = getState().user.currentUser;

        if (currentUser && currentUser.stripeAccount) {
          dispatch(fetchStripeAccount());
        }

        dispatch(queryShows(organizationProfiles));
        return response;
      } catch (e) {
        throw e;
      }
    }

    const payload = {
      id: new UUID(id),
      include: ['author', 'images'],
      'fields.image': [
        'variants.landscape-crop',
        'variants.landscape-crop2x',
        'variants.landscape-crop4x',
        'variants.landscape-crop6x',
      ],
    };

    try {
      const response = await Promise.all([
        dispatch(requestShowListing(payload)),
        dispatch(fetchCurrentUser()),
        dispatch(queryShows(organizationProfiles)),
        dispatch(showListingInvites(id)),
      ]);
      const currentUser = getState().user.currentUser;

      if (currentUser && currentUser.stripeAccount) {
        dispatch(fetchStripeAccount());
      }

      // Because of two dispatch functions, response is an array.
      // We are only interested in the response from requestShowListing here,
      // so we need to pick the first one
      if (response[0].data && response[0].data.data) {
        const listing = response[0].data.data?.id
          ? response[0].data.data
          : response[0].data.data.data;

        // If the listing doesn't have availabilityPlan yet
        // use the defaul timezone
        const availabilityPlan = listing?.attributes?.availabilityPlan;

        const tz = availabilityPlan
          ? listing.attributes.availabilityPlan.timezone
          : typeof window !== 'undefined'
          ? getDefaultTimeZoneOnBrowser()
          : 'Etc/UTC';

        const today = new Date();
        const start = resetToStartOfDay(today, tz, 0);
        // Query range: today + 364 days
        const exceptionRange = 364;
        const end = resetToStartOfDay(today, tz, exceptionRange);

        // NOTE: in this template, we don't expect more than 100 exceptions.
        // If there are more exceptions, pagination kicks in and we can't use frontend sorting.
        const params = {
          listingId: listing.id,
          start,
          end,
        };
        dispatch(requestFetchAvailabilityExceptions(params));
      }

      return response;
    } catch (e) {
      throw e;
    }
  });
};
