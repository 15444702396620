import React from 'react';
import moment from 'moment-timezone';

import css from './BookingPanel.module.css';

const TimeRange = props => {
  const { startDate, endDate, timeZone, dateType } = props;

  const start = moment.tz(startDate, timeZone).format('MMM DD');
  const startTime = moment.tz(startDate, timeZone).format('h:mm A');
  const endTime = moment.tz(endDate, timeZone).format('h:mm A');
  const shortTimeZone = moment.tz(timeZone).format('z');

  if (dateType === 'date') {
    return <span className={css.timeslotDate}>{start}</span>;
  }

  if (dateType === 'time') {
    return <span className={css.timeslotTime}>{`${startTime}-${endTime} (${shortTimeZone})`}</span>;
  }

  return <span className={css.timeslotDate}>{start}</span>;
};

export default TimeRange;
