// These helpers are calling FTW's own server-side routes
// so, they are not directly calling Marketplace API or Integration API.
// You can find these api endpoints from 'server/api/...' directory

import { types as sdkTypes, transit } from './sdkLoader';
import config from '../config';
import Decimal from 'decimal.js';
import axios from 'axios';

const integrationApiUrl = '/api/integration';

export const apiBaseUrl = () => {
  const port = process.env.REACT_APP_DEV_API_SERVER_PORT;
  const useDevApiServer = process.env.NODE_ENV === 'development' && !!port;
  const isWindowDefined = typeof window !== 'undefined';

  // In development, the dev API server is running in a different port
  if (useDevApiServer) {
    return `http://localhost:${port}`;
  }

  // Otherwise, use the same domain and port as the frontend
  //
  // Make sure to check if the window is defined before calling
  // the window object, as we are calling the API directly from
  // the front-end

  return isWindowDefined ? `${window.location.origin}` : process.env.REACT_APP_CANONICAL_ROOT_URL;
};

// Application type handlers for JS SDK.
//
// NOTE: keep in sync with `typeHandlers` in `server/api-util/sdk.js`
export const typeHandlers = [
  // Use Decimal type instead of SDK's BigDecimal.
  {
    type: sdkTypes.BigDecimal,
    customType: Decimal,
    writer: v => new sdkTypes.BigDecimal(v.toString()),
    reader: v => new Decimal(v.value),
  },
];

const includeHeaders = body => {
  const isBodyFormData = body instanceof FormData;
  return !isBodyFormData
    ? {
        headers: {
          'Content-Type': 'application/transit+json',
        },
      }
    : {};
};

const serialize = data => {
  return transit.write(data, { typeHandlers, verbose: config.sdk.transitVerbose });
};

const deserialize = str => {
  return transit.read(str, { typeHandlers });
};

const post = (path, body) => {
  const url = `${apiBaseUrl()}${path}`;
  const options = {
    method: 'POST',
    credentials: 'include',
    ...includeHeaders(body),
    body,
  };
  return window.fetch(url, options).then(res => {
    const contentTypeHeader = res.headers.get('Content-Type');
    const contentType = contentTypeHeader ? contentTypeHeader.split(';')[0] : null;

    if (res.status >= 400) {
      return res.json().then(data => {
        let e = new Error();
        e = Object.assign(e, data);

        throw e;
      });
    }

    if (contentType === 'application/transit+json') {
      return res.text().then(deserialize);
    } else if (contentType === 'application/json') {
      return res.json();
    }

    return res.text();
  });
};

const get = (path, params) => {
  const url = `${apiBaseUrl()}${path}`;
  return axios(url, { params });
};

// Fetch transaction line items from the local API endpoint.
//
// See `server/api/transaction-line-items.js` to see what data should
// be sent in the body.
export const transactionLineItems = body => {
  return post('/api/transaction-line-items', body);
};

// Initiate a privileged transaction.
//
// With privileged transitions, the transactions need to be created
// from the backend. This endpoint enables sending the booking data to
// the local backend, and passing that to the Marketplace API.
//
// See `server/api/initiate-privileged.js` to see what data should be
// sent in the body.
export const initiatePrivileged = body => {
  return post('/api/initiate-privileged', serialize(body));
};

// Transition a transaction with a privileged transition.
//
// This is similar to the `initiatePrivileged` above. It will use the
// backend for the transition. The backend endpoint will add the
// payment line items to the transition params.
//
// See `server/api/transition-privileged.js` to see what data should
// be sent in the body.
export const transitionPrivileged = body => {
  return post('/api/transition-privileged', serialize(body));
};

// Create user with identity provider (e.g. Facebook or Google)
//
// If loginWithIdp api call fails and user can't authenticate to Flex with idp
// we will show option to create a new user with idp.
// For that user needs to confirm data fetched from the idp.
// After the confirmation, this endpoint is called to create a new user with confirmed data.
//
// See `server/api/auth/createUserWithIdp.js` to see what data should
// be sent in the body.
export const createUserWithIdp = body => {
  return post('/api/auth/create-user-with-idp', serialize(body));
};

export const emailValid = params => {
  return get('/api/auth/email-valid', params);
};

// Server routes that are used to communicate with integration API
//
// See `server${integrationApiUrl}` to see what data should be sent
// as the params or in the body
export const integrationAPI = {
  users: {
    show: params => get(`${integrationApiUrl}/users/show`, params),
    query: params => get(`${integrationApiUrl}/users/query`, params),
    payoutsEnabled: params => get(`${integrationApiUrl}/users/payouts-enabled`, params),
    updateProfile: body => post(`${integrationApiUrl}/users/update-profile`, serialize(body)),
  },
  availabilityExceptions: {
    query: params => get(`${integrationApiUrl}/availability-exceptions/query`, params),
    create: body => post(`${integrationApiUrl}/availability-exceptions/create`, serialize(body)),
    createMultiple: body =>
      post(`${integrationApiUrl}/availability-exceptions/create-multiple`, serialize(body)),
    delete: body => post(`${integrationApiUrl}/availability-exceptions/delete`, serialize(body)),
    deleteMultiple: body =>
      post(`${integrationApiUrl}/availability-exceptions/delete-multiple`, serialize(body)),
  },
  transactions: {
    show: params => get(`${integrationApiUrl}/transactions/show`, params),
    query: params => get(`${integrationApiUrl}/transactions/query`, params),
    updateMetadata: body =>
      post(`${integrationApiUrl}/transactions/update-metadata`, serialize(body)),
    createPayment: body =>
      post(`${integrationApiUrl}/transactions/create-payment`, serialize(body)),
  },
  listings: {
    show: params => get(`${integrationApiUrl}/listings/show`, params),
    query: params => get(`${integrationApiUrl}/listings/query`, params),
    queryVideos: params => get(`${integrationApiUrl}/listings/query-videos`, params),
    close: body => post(`${integrationApiUrl}/listings/close`, serialize(body)),
    open: body => post(`${integrationApiUrl}/listings/open`, serialize(body)),
    update: body => post(`${integrationApiUrl}/listings/update`, serialize(body)),
    create: body => post(`${integrationApiUrl}/listings/create`, serialize(body)),
  },
  reviews: {
    query: params => get(`${integrationApiUrl}/reviews/query`, params),
    send: body => post(`${integrationApiUrl}/reviews/send`, serialize(body)),
    addLike: body => post(`${integrationApiUrl}/reviews/add-like`, serialize(body)),
    addDislike: body => post(`${integrationApiUrl}/reviews/add-dislike`, serialize(body)),
  },
  comments: {
    query: params => get(`${integrationApiUrl}/comments/query`, params),
    send: body => post(`${integrationApiUrl}/comments/send`, serialize(body)),
    update: body => post(`${integrationApiUrl}/comments/update`, serialize(body)),
    delete: body => post(`${integrationApiUrl}/comments/delete`, serialize(body)),
    addLike: body => post(`${integrationApiUrl}/comments/add-like`, serialize(body)),
    addDislike: body => post(`${integrationApiUrl}/comments/add-dislike`, serialize(body)),
  },
  images: {
    upload: body => post(`${integrationApiUrl}/images/upload`, body),
  },
  subscriptions: {
    createFreeSubscription: body =>
      post(`${integrationApiUrl}/subscriptions/listing/create-free`, serialize(body)),
  },
};

export const googleStorageAPI = {
  images: {
    upload: body => post('/api/google-storage/images/upload', body),
  },
  documents: {
    upload: body => post('/api/google-storage/documents/upload', body),
  },
  videos: {
    upload: body => post('/api/google-storage/videos/upload', body),
  },
};

export const organizationSettingsAPI = {
  show: params => get('/api/organization-profiles/show', params),
  query: params => get('/api/organization-profiles/query', params),
};

export const creditsAPI = {
  query: params => get('/api/credits/query', params),
};

export const wherebyAPI = {
  createMeeting: body => post('/api/whereby/create-meeting', serialize(body)),
  getMeeting: params => get('/api/whereby/get-meeting', params),
};

export const mailchimpAPI = {
  subscribe: body => post('/api/mailchimp/subscribe', serialize(body)),
};

export const emailAPI = {
  organizationProfiles: {
    invitation: body => post('/api/email/organization-profiles/invitation', serialize(body)),
    delete: body => post('/api/email/organization-profiles/delete', serialize(body)),
  },
  listings: {
    invitation: body => post('/api/email/listings/invitation', serialize(body)),
  },
  contact: {
    send: body => post('/api/email/contact/send', serialize(body)),
  },
};

export const communityAPI = {
  comments: {
    create: body => post('/api/community/comments/create', serialize(body)),
    delete: body => post('/api/community/comments/delete', serialize(body)),
  },
};

export const intercomAPI = {
  verification: {
    generateHash: params => get('/api/intercom/generate-hash', params),
  },
};

export const stripeServerAPI = {
  getReceiptUrl: params => get('/api/stripe/get-receipt-url', params),
  calculateTax: body => post('/api/stripe/calculate-tax', serialize(body)),
  createTaxTransaction: body => post('/api/stripe/create-tax-transaction', serialize(body)),
};
